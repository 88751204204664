<template>
    <div
        id="J_summary-teacher-form-wrapper"
        class="common-form-main common-form-layout--scroll summary-teacher-form"
    >
        <div class="common-form-view">
            <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-position="right"
                label-width="120px"
            >
                <form-main-title
                    id="J_teachInfo_basic_info"
                    title="基础信息"
                ></form-main-title>
                <el-form-item
                    label="头像"
                    prop="headImg"
                    style="margin-top: 13px"
                >
                    <div class="avatar-upload-wrap">
                        <div>
                            <el-upload
                                ref="avatarUpload"
                                action="#"
                                accept=".jpg, .jpeg, .bmp, .gif, .png"
                                :show-file-list="false"
                                :auto-upload="false"
                                :on-change="handleFileChange"
                                :disabled="query.pageSource==='userProfile'"
                                class="avatar-upload"
                            >
                                <div v-if="ruleForm.headImg" class="avatar-upload-img" @click.stop>
                                    <img :src="ruleForm.headImg" alt="">
                                    <div class="avatar-upload-img-operate">
                                        <!-- <i class="el-icon-zoom-in" @click.stop="bigShow(ruleForm.headImg)"></i> -->
                                        <i class="el-icon-upload2" @click.stop="handleUpload"></i>
                                        <i class="el-icon-delete" @click.stop="handleDelete"></i>
                                    </div>
                                    <div 
                                        class="status-wrap"
                                        :style="{
                                            background: getValueByState('backGroundColor'),
                                            color:getValueByState('textColor')
                                        }"
                                    >
                                        <i 
                                            class="font_family"
                                            :class="[getValueByState('icon')]"
                                        ></i>
                                        {{ getValueByState('name') }}
                                    </div>
                                </div>
                                <i v-else class="avatar-upload-icon el-icon-plus"></i>
                            </el-upload>
                            <div 
                                v-if="ruleForm.headImg && headImgInfo.auditState == '2'"
                                class="error-tip"
                                @click="handleErrorTipClick"
                            >   
                                <div class="error-tip-text">{{headImgInfo.tip}}</div>
                                <i class="el-icon-warning-outline"></i>
                                
                            </div>
                        </div>
                        <div class="tips-wrap">
                            <div class="tips-title">
                                <div class="title">照片上传要求：</div>
                                <div
                                    v-if="query.pageType === 'edit'" 
                                    class="tip-text"
                                ><i class="el-icon-warning-outline"></i>更换头像图片后，系统将会自动保存更改</div>
                            </div>
                            <div class="tips-content">1、照片背景选择白色为最佳，保证人脸光照足够、受光均匀，人脸面部图像清晰；</div>
							<div class="tips-content">2、请保证上传的照片为正面照片，拍照范围为人员的肩膀及以上；</div>
							<div class="tips-content">3、人脸没有其他物体遮挡，如佩戴眼镜请保证眼镜框没有遮挡眼睛，请不要佩戴口罩拍照；</div>
							<div class="tips-content">4、上传的照片必须是手机原相机拍照，具有真实性，不要美图、P图等，不要带美颜或者其他特效的照片；</div>
                            <div class="tips-content">5、支持格式为JPG，JPEG，GIF，BMP或PNG大小不超过20M。</div>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item
                    label="姓名"
                    prop="teacherName"
                >
                    <el-input
                        v-model="ruleForm.teacherName"
                        placeholder="请输入"
                        class="input-width-small"
                        @change="synchronous"
                        :disabled="query.pageSource==='userProfile'"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="手机号"
                    prop="phone"
                >
                    <el-input
                        class="input-width-small"
                        v-model="ruleForm.phone"
                        placeholder="请输入"
                        @change="changePhone"
                        @blur="handlePhoneBlur"
                        :disabled="query.pageSource==='userProfile'"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="工号"
                    prop="onlyCode"
                >
                    <el-input
                        class="input-width-small"
                        v-model="ruleForm.onlyCode"
                        placeholder="请输入"
                        :disabled="query.pageSource==='userProfile'"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="性别"
                    prop="sex"
                >
                    <el-radio
                        v-model="ruleForm.sex"
                        label="1"
                        >男</el-radio
                    >
                    <el-radio
                        v-model="ruleForm.sex"
                        label="2"
                        >女</el-radio
                    >
                </el-form-item>
                <el-form-item
                    label="消费账号"
                >
                    <el-input
                        v-model="ruleForm.consumNumber"
                        class="input-width-small"
                        placeholder="请输入"
                        :disabled="query.pageSource==='userProfile'"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="车牌号"
                >
                    <div>
                        <car v-bind:plate-data="ruleForm.carNumbers" ref="carPlate" @carNumber="getCarNumber"></car>
                    </div>
                </el-form-item>
                <form-main-title
                    id="J_teachInfo_position_info"
                    title="职务信息"
                ></form-main-title>
                <el-form-item
                    label="任职部门"
                    prop="orgList"
                    style="margin-top: 13px"
                >
                    <m-tree-select
                        ref="treeSelect1"
                        multiple
                        class="input-width-small"
                        v-model="ruleForm.orgList"
                        :checkStrictly="true"
                        :collapse-tags="true"
                        :data="options"
                        :disabled="query.pageSource==='userProfile'"
                    ></m-tree-select>
                    <div class="tips">
                        无部门可选？请点击
                        <span
                            class="text"
                            @click="
                                $router.push({
                                    name: 'SystemOrganizationalStructure',
                                })
                            "
                            >部门设置</span
                        >
                        进行添加
                    </div>
                </el-form-item>
                <el-form-item
                    label="是否任教"
                    prop="isSubjectTeacher"
                >
                    <el-radio
                        v-model="ruleForm.isSubjectTeacher"
                        label="1"
                        >是</el-radio
                    >
                    <el-radio
                        v-model="ruleForm.isSubjectTeacher"
                        label="2"
                        >否</el-radio
                    >
                </el-form-item>
                <el-form-item
                    v-if="ruleForm.isSubjectTeacher === '1'"
                    label="任教班级"
                    prop="subjectClassList"
                >
                    <m-tree-select
                        ref="treeSelect2"
                        class="input-width-small"
                        filterable
                        multiple
                        v-model="ruleForm.subjectClassList"
                        :filterFn="filterFn"
                        :collapse-tags="true"
                        :checkStrictly="false"
                        :data="options2"
                    ></m-tree-select>
                    <div class="tips">
                        无班级可选？请点击
                        <span
                            class="text"
                            @click="
                                $router.push({
                                    name: 'SystemOrganizationalStructure',
                                })
                            "
                            >班级设置</span
                        >
                        进行添加
                    </div>
                </el-form-item>
                <el-form-item
                    v-if="ruleForm.isSubjectTeacher === '1'"
                    label="任教科目"
                    prop="subject"
                >
                    <el-select
                        v-model="ruleForm.subject"
                        class="input-width-small"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="it in subjects"
                            :key="it.value"
                            :label="it.label"
                            :value="it.value"
                        />
                    </el-select>
                    <div class="tips">
                        无科目可选？请点击
                        <span
                            class="text"
                            @click="
                                $router.push({
                                    name: 'SystemDict',
                                    query: {
                                        dictKey: 'teachingSubjects',
                                    },
                                })
                            "
                            >科目设置</span
                        >
                        进行添加
                    </div>
                </el-form-item>
                <el-form-item
                    label="班主任"
                    prop="isHeadmaster"
                    :style="{
                        width: ruleForm.isHeadmaster === '2' ? '100%' : '50%',
                        height: '36px',
                    }"
                >
                    <el-radio
                        v-model="ruleForm.isHeadmaster"
                        label="1"
                        >是</el-radio
                    >
                    <el-radio
                        v-model="ruleForm.isHeadmaster"
                        label="2"
                        >否</el-radio
                    >
                </el-form-item>
                <el-form-item
                    v-if="ruleForm.isHeadmaster === '1'"
                    label="负责班级"
                    prop="manageClassList"
                >
                    <m-tree-select-x
                        ref="treeSelect3"
                        class="input-width-small"
                        filterable
                        multiple
                        v-model="ruleForm.manageClassList"
                        :filterFn="filterFn"
                        :collapse-tags="true"
                        :checkStrictly="false"
                        :data="options2"
                        @visibleChange="handleManageClassVisibleChange"
                    ></m-tree-select-x>
                    <el-tooltip effect="dark" content="班主任负责班级" placement="top-start">
                        <i class="el-icon-question item"></i>
                    </el-tooltip>
                </el-form-item>
                <el-form-item
                    label="职务"
                    prop="post"
                >
                    <el-select
                        ref="postSelect"
                        class="input-width-small"
                        v-model="ruleForm.post"
                        placeholder="请选择职务"
                        multiple
                        :multiple-limit="5"
                        filterable
                        clearable
                        @change="postName"
                        :disabled="query.pageSource==='userProfile'"
                    >
                        <el-option
                            v-for="it in postList"
                            :key="it.value"
                            :label="it.label"
                            :value="it.value"
                        />
                    </el-select>
                    <div class="tips">
                        无职务可选？请点击
                        <span
                            class="text"
                            @click="
                                $router.push({
                                    name: 'SystemDict',
                                    query: {
                                        dictKey: 'teachingPost',
                                    },
                                })
                            "
                            >职务设置</span
                        >
                        进行添加
                    </div>
                </el-form-item>
                <el-form-item
                    label="班级权限"
                    prop="viewList"
                >
                    <m-tree-select
                        ref="treeSelect4"
                        class="input-width-small"
                        filterable
                        multiple
                        v-model="ruleForm.viewList"
                        :filterFn="filterFn"
                        :collapse-tags="true"
                        :checkStrictly="false"
                        :data="options2"
                        :disabled="query.pageSource==='userProfile'"
                    ></m-tree-select>
                    <el-tooltip effect="dark" content="班级数据权限，可查看哪些班级的数据。" placement="top-start">
                        <i class="el-icon-question item"></i>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="入职时间">
                    <el-date-picker
                        v-model="ruleForm.entryTime"
                        class="input-width-small"
                        type="date"
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        placeholder="选择日期"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item
                    label="序列号"
                    prop="serialNumber"
                >
                    <el-input
                        class="input-width-small"
                        v-model="ruleForm.serialNumber"
                        placeholder="请输入"
                        :disabled="query.pageSource==='userProfile'"
                    ></el-input>
                    <el-tooltip effect="dark" content="班牌序列号" placement="top-start">
                        <i class="el-icon-question item"></i>
                    </el-tooltip>
                </el-form-item>
                <form-main-title
                    id="J_teachInfo_account_info"
                    title="账号信息"
                ></form-main-title>
                <el-form-item
                    style="margin-top: 13px"
                    label="登录手机号"
                    prop="loginName"
                >
                    <el-input
                        v-model="ruleForm.loginName"
                        class="input-width-small"
                        placeholder="请输入登录手机号"
                        @blur="handleLoginNameBlur"
                        :disabled="query.pageSource==='userProfile'"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="用户名"
                    prop="userName"
                >
                    <el-input
                        v-model="ruleForm.userName"
                        class="input-width-small"
                        placeholder="请输入用户名"
                        :readonly="userInput"
                        @focus="handleUserBlur"
                        @change="controlSynchronous"
                        :disabled="query.pageSource==='userProfile'"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="登录密码"
                    :prop="disabledPassword ? '' : 'password'"
                >
                    <el-input
                        :disabled="disabledPassword || query.pageSource==='userProfile'"
                        class="input-width-small"
                        show-password
                        v-model="ruleForm.password"
                        clearable
                        placeholder="请输入登录密码"
                        :readonly="userInput"
                        @focus="handleUserBlur"
                    ></el-input>
                </el-form-item>
                <form-main-title
                    id="J_teachInfo_data_info"
                    title="数据权限"
                ></form-main-title>
                <el-form-item
                    label="部门权限"
                    style="margin-top: 13px"
                >
                    <m-tree-select
                        class="input-width-small"
                        ref="treeSelect1"
                        multiple
                        v-model="ruleForm.powerOrganList"
                        :checkStrictly="false"
                        :collapse-tags="true"
                        :data="options3"
                        :disabled="query.pageSource==='userProfile'"
                    ></m-tree-select>
                    <div class="tips">
                        无部门可选？请点击
                        <span
                            class="text"
                            @click="
                                $router.push({
                                    name: 'SystemOrganizationalStructure',
                                })
                            "
                            >部门数据权限设置</span
                        >
                        进行添加
                    </div>
                    <el-tooltip effect="dark" content="部门数据权限，可查看哪些部门的数据。" placement="top-start">
                        <i class="el-icon-question item"></i>
                    </el-tooltip>
                </el-form-item>
                <el-form-item
                    label="角色组"
                    class="newRoleList"
                >
                    <el-checkbox-group v-model="ruleForm.roleIdList" :disabled="query.pageSource==='userProfile'">
                        <el-checkbox
                            style="margin-right: 20px;"
                            v-for="item in newRoleList"
                            :key="item.id"
                            :label="item.id"
                            >{{ item.roleName }}</el-checkbox
                        >
                    </el-checkbox-group>
                </el-form-item>
            </el-form>
        </div>
        <!-- 上传头像 -->
        <dialog-wrapper
            :dialogObj="cropperDialogObj"
            @handleClose="handleCropperClose"
        >
            <div class="cropper-content">
                <div class="cropper" style="text-align:center">
                    <!--
                        :img="option.img"
                        :info="option.info"
                        :outputType="option.outputType"
                        :fixed-box="option.fixedBox"
                        :auto-crop-width="option.autoCropWidth"
                        :auto-crop-height="option.autoCropHeight"
                        :can-scale="option.canScale"
                        :auto-crop="option.autoCrop"
                        :info-true="option.infoTrue"
                        :outputType="option.outputType"

                     -->
                    <vue-cropper
                        ref="cropper"
                        :img="option.img"
                        :info="option.info"
                        :fixed-box="option.fixedBox"
                        :can-scale="option.canScale"
                        :auto-crop="option.autoCrop"
                        :info-true="option.infoTrue"
                        :auto-crop-width="option.autoCropWidth"
                        :auto-crop-height="option.autoCropHeight"
                        :outputSize="option.size"
                        :high="false"
                    />
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleCropperClose">取 消</el-button>
                <el-button :loading="cropperLoading" type="primary" @click="finish">确定</el-button>
            </span>
        </dialog-wrapper>
        <dialog-image :dialogImageObj="dialogImageObj"  @closed="closedBtn" ></dialog-image>
    </div>
</template>
<script>
import { VueCropper } from 'vue-cropper'
import { DialogWrapper, MTreeSelect,  MTreeSelectX} from "common-local";
import FormMainTitle from "../Sub/FormMainTitle/index.vue";
import {getToken} from "@/libs/auth";
import { auditStateConfig } from "@/assets/data/uploadImage";
import { CommonModel } from "@/models/Common";
import DialogImage from "@/components/scrollWrapper/Sub/DialogImage.vue";
import car from './car.vue'

export default {
    name: "SummaryTeacherForm",
    components: {
        DialogWrapper,
        MTreeSelect,
        MTreeSelectX,
        FormMainTitle,
        VueCropper,
        DialogImage,
        car
    },
    props: {
        ruleForm: Object,
        rules: Object,
        options: Array,
        options2: Array,
        options3: Array,
        subjects: Array,
        postList: Array,
        newRoleList: Array,
        userInput: Boolean,
        disabledPassword: Boolean,
        oriBasicForm: Object,
        query: Object,
        archivesCompleteness: Number,
        detailFormShow: Boolean,
        headImgInfo: Object,
    },
    data() {
        return {
            auditStateConfig,
            cropperLoading: false,
            cropperDialogObj: {
                title: '裁剪图片',
                dialogVisible: false,
                width: '800px'
            },
            option: {
                img: '', // 裁剪图片的地址
                file: '',
                info: false, // 裁剪框的大小信息
                fixedBox: true,
                autoCropWidth: 540,
                autoCropHeight: 720,
                canScale: true, // 图片是否允许滚轮缩放
                autoCrop: true, // 是否默认生成截图框
                infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
                outputType: 'jpg',
                size: 0.9
            },
            dialogImageObj: {
                show: false,
                url: ""
            },
            carNumbers:{}
        }
    },
    computed: {
        action() {
            return "/api/school/common/uploadFile";
        },
        uploadAvatar() {
            return require("@/assets/images/upload-avatar.png");
        },
        triangleUp() {
            return require("@/assets/images/stuInfoTemplate/triangle-up.png");
        },
        triangleDown() {
            return require("@/assets/images/stuInfoTemplate/triangle-down.png");
        },
        headers() {
            return {
                Authorization: getToken(),
            };
        },
    },
    methods: {
        bigShow(url){
            this.dialogImageObj.url = url;
            this.dialogImageObj.show = true;
        },
        closedBtn(){
            this.dialogImageObj = {
                show: false,
                url: ""
            }
        },
        /**
         * @Description 上传头像成功回调
         * @DoWhat 上传头像成功回调
         * @UseScenarios 上传头像成功回调
         * @Attention 无
         * @Author 韩熙昊
         * @Date 2022/11/10 15:05
         **/
        handleAvatarSuccess(res, file) {
            this.$emit("handleAvatarSuccess", res.data.url);
        },
        changePhone() {
            this.$emit("changePhone");
        },
        handlePhoneBlur() {
            this.$emit("handlePhoneBlur");
        },
        postName() {
            this.$nextTick(() => {
                let postName = this.$refs.postSelect.selected
                    .map((i) => i.label)
                    .join(",");

                this.$emit("postName", postName);
            });
        },
        filterFn(data) {
            return data.filter((i) => i.organType === "4");
        },
        handleLoginNameBlur() {
            this.$emit("handleLoginNameBlur");
        },
        handleUserBlur() {
            this.$emit("handleUserBlur");
        },
        showStowDetailForm () {
            this.$emit('showStowDetailForm')
        },
        getTeacherBaseInfo () {
            this.$emit('getTeacherBaseInfo')
        },
        /**
         * @Description 上传头像校验
         * @DoWhat 上传头像校验
         * @UseScenarios 上传头像校验
         * @Attention 无
         * @Author 韩熙昊
         * @Date 2022/11/10 15:04
         **/
        submitForm () {
            this.$refs.carPlate.validatesubmit()
            const nameList = ['one','two','three','four','five'];
            var carNUmbers = [];
            for (let i = 0; i < nameList.length; i++) {
                if(this.carNumbers[nameList[i]].car && this.carNumbers[nameList[i]].isSubmit== true){
                    carNUmbers.push(this.carNumbers[nameList[i]].car);
                }else if(this.carNumbers[nameList[i]].isSubmit== false){
                    this.$message.warning('车牌信息错误');
                    return;
                }
            }
            this.ruleForm.carNumbers = carNUmbers;
            console.log(this.ruleForm.carNumbers,'hjm');
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    this.$emit('addNewSector')
                } else {
                    return false;
                }
            });
        },
        getCarNumber(val){
            this.carNumbers = val;
        },
        /**
         * @Description: 更高页面布局
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-07-24 16:41:30
         */
         changePageLayout (status) {
            let oFormMain = document.querySelector('#J_summary-teacher-form-wrapper')
            console.log(oFormMain.classList,'classList')
            if (status) {

            } else {

            }
         },
         synchronous () {
            this.$emit('synchronous')
         },
         controlSynchronous () {
            this.$emit('controlSynchronous')
         },
        handleFileChange(file) {
            let flag = this.beforeAvatarUpload(file);
            if (flag !== false) {
                this.$nextTick(() => {
                    // 上传预览图片  是个临时的缓存地址
                    this.option.img = URL.createObjectURL(file.raw);
                    this.option.file = file;
                    this.cropperDialogObj.dialogVisible = true;
                });
            }
        },
        beforeAvatarUpload(file) {

            const types = [".jpg", ".JPG", ".png", ".PNG", ".jpeg", ".JPEG", ".bmp", ".BMP", ".gif", ".GIF"];
            const isImg = types.includes(file.name.substring(file.name.lastIndexOf(".")));
            const isLt2M = file.size / 1024 / 1024 < 20;
            const is50 = file.name.length < 50;
            // 判断图像上传大小
            let riseze = file.size / 1024;
            console.log(riseze,'图片真实大小')
            if (riseze < 50) {
                this.$message.error("上传图片不能小于50kb!");
                return false;
            }

            if (riseze < 200) {
                console.log('图像小于200')
                this.option.size = 0.99;
            } else {
                this.option.size = 0.9;
                console.log('图像大于200')
            }

            if (!is50) {
                this.$message.error("上传图片名称不能超过50个字符!");
                return false;
            }
            if (!isImg) {
                this.$message.error("上传图片不符合格式!");
                return false;
            }
            if (!isLt2M) {
                this.$message.error("上传图片大小不能超过20MB!");
                return false;
            }
        },
        finish() {
            this.$refs.cropper.getCropBlob((data) => {
                const reader = new FileReader();
                reader.readAsDataURL(data);
                reader.onload = function() {
                    if (reader.readyState == 2) {
                        const img = new Image();
                        img.src = reader.result;
                        img.onload = function() {
                            console.log(img.width, img.height,'width-height')
                        };
                    }
                };

                this.cropperLoading = true;
                let newFile = new window.File([data], this.option.file.name, { type: data.type });
                console.log(newFile,'file-教职工')
                let dataType = this.query.pageType === 'edit' ? "edit" : "add";
                let formData = new FormData();
                formData.append("file", newFile);
                formData.append('personType' ,'teacher');
                formData.append("dataType", dataType);
                if(this.query.pageType === 'edit'){
                    formData.append('personId', this.ruleForm.id);
                }
                let commonModel = new CommonModel();
                commonModel.uploadByPeople(formData).then((res) => {
                    if (res.data.code === "200") {
                        this.handleAvatarSuccess(res.data);
                        this.handleCropperClose();
                        this.setHeadImgInfo();
                    } else {
                        this.$message.error(res.data.msg);
                    }
                    setTimeout(() => {
                        this.cropperLoading = false;
                    }, 300);
                }).catch(() => {
                    setTimeout(() => {
                        this.cropperLoading = false;
                    }, 300);
                });
            });
        },
        handleCropperClose() {
            this.cropperDialogObj.dialogVisible = false;
        },
        handleUpload() {
            this.$refs.avatarUpload.$refs['upload-inner'].handleClick()
        },
        handleDelete() {
            this.ruleForm.headImg = ""
        },
        // 监听负责班级下拉关闭
        handleManageClassVisibleChange(val){
            let oldManageClassList = this.ruleForm.oldManageClassList; //旧的负责班级
            let newManageClassList = this.ruleForm.manageClassList; //新的负责班级
            let classList = this.ruleForm.viewList; //班级权限
            let newAdd = newManageClassList.filter(item => !oldManageClassList.map(oItem => oItem.id).includes(item.id)); //新增的负责班级
            let newDelete = oldManageClassList.filter(item => !newManageClassList.map(oItem => oItem.id).includes(item.id)); //删除的负责班级

            if(newAdd.length > 0){
                newAdd.forEach(item => {
                    // 如果班级权限中没有这个负责班级，则添加
                    if(!classList.map(cItem => cItem.id).includes(item.id)){
                        classList.push(item);
                    }
                })
            }

            if(newDelete.length > 0){
                newDelete.forEach(item => {
                    // 如果班级权限中有这个负责班级，则删除
                    if(classList.map(cItem => cItem.id).includes(item.id)){
                        classList = classList.filter(cItem => cItem.id != item.id);
                    }
                })
            }

            this.ruleForm.viewList = classList;
            this.ruleForm.oldManageClassList = this.ruleForm.manageClassList;

        },
        /**
         * @Description: 根据 keyName 获取对应状态下的值
         * @Author: 张冰华
         * @Date: 2024-08-29 11:45:18
         * @param { String } keyName
         * @returns { String } res auditStateConfig配置中对应的值
         */        
        getValueByState(keyName) {
            let auditState = this.headImgInfo.auditState;
            // let auditState = this.userInfo.auditState;
            if(!auditState) return ""
            let res = this.auditStateConfig.filter(item => item.state == auditState)[0][keyName];
            return res;
        },
        handleErrorTipClick() {
            this.$alert(this.headImgInfo.tip, '审核失败原因', {
                confirmButtonText: '确定',
                callback: action => {
                    
                }
            });
        },
        setHeadImgInfo() {
            let obj = { ...this.headImgInfo }
            obj.auditState = "0";
            this.$emit('setHeadImgInfo', obj)
        }
    },
    watch: {
        'detailFormShow': {
            handler (newVal) {
                this.changePageLayout(newVal)
            },
            deep: true
        }
    },
};
</script>
<style lang="scss" scoped>
.common-form-view {
    text-align: left;
    .upload-img {
        width: 80px;
        height: 80px;
        background: #f7f8fa;
    }
    .upload-text {
        font-size: 12px;
        line-height: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
    }
    .tips {
        margin-top: 3px;
        line-height: 15px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        white-space: nowrap;

        .text {
            color: #3C7FFF;
            cursor: pointer;
        }
    }
    .newRoleList {
        white-space: pre-wrap;
    }
}
.show-stow-btn {
    margin-top: 10px;
    height: 48px;
    background: #f7fafc;
    border-radius: 6px 6px 0px 0px;
    line-height: 48px;
    text-align: center;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #3C7FFF;
    cursor: pointer;

    img {
        width: 12px;
        height: 8px;
        margin-left: 8px;
        margin-bottom: 2px;
    }
}
.detail-form {
    width: 883px;
    background-color: #ffffff;
    border-radius: 0px 0px 6px 6px;
    // flex: 1;
}
.item {
    position: absolute;
    left: 452px;
    top: 10px;
    color: #595959;
}
.item:hover {
    color: #3C7FFF;
}

.avatar-upload-wrap {
    display: flex;

    .avatar-upload {
        flex-shrink: 0;
        align-self: flex-start;
        width: 80px;
        height: 106px;
        border: 1px solid #d9d9d9;
        box-sizing: border-box;
        text-align: center;
        overflow: hidden;

        ::v-deep .el-upload--text {
            width: 80px;
            height: 106px;
        }

        .avatar-upload-img {
            position: relative;
            width: 80px;
            height: 106px;

            img {
                width: 100%;
                height: 100%;
            }

            .avatar-upload-img-operate {
                display: flex;
                cursor: default;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                background: rgba(0, 0, 0, 0.5);
                transition: opacity .3s;

                .el-icon-upload2, .el-icon-delete , .el-icon-zoom-in{
                    cursor: pointer;
                    color: #ffffff;
                    margin: 0 5px;
                    font-size: 16px;
                }
            }

            &:hover .avatar-upload-img-operate {
                opacity: 1;
            }

            .status-wrap {
                position: absolute;
                z-index: 10;
                bottom: 0px;
                display: flex;
                align-items: center;
                width: 98%;
                font-size: 12px;
                line-height: 12px;
                padding: 5px;
                .font_family {
                    margin-right: 5px;
                    font-size: 12px;
                }
            }
        }

        &-icon {
            font-size: 16px;
            color: #d9d9d9;
            line-height: 106px;
            text-align: center;
        }
    }

    .error-tip {
        width: 106px;
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 12px;
        color: #E64545;
        margin-top: 10px;
        cursor: pointer;
        .error-tip-text {
            width: 65%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        } 
    }

    .tips-wrap {
        margin-left: 24px;

        .tips-title {
            display: flex;
            align-items: center;
			.title {
                font-size: 14px;
                color: #333333;
                line-height: 20px;
            }
            .tip-text {
                display: flex;
                align-items: center;
                font-size: 12px;
                color: #E5A422;
                margin-left: 10px;
                i {
                    margin-right: 6px;
                }
            }
		}

        .tips-content {
            font-size: 12px;
            color: #999999;
            line-height: 20px;
        }
    }
}

.cropper-content {
    display: flex;
    justify-content: center;

    .cropper {
        text-align: center;
        width: 540px;
        height: 720px;
    }

    ::v-deep .cropper-crop-box {
        background-color: #fff;
    }
}
</style>
